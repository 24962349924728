import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Offer, Order, Products, User } from '../../shared/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpClient) {}
  public bookmarkedProducts = new BehaviorSubject<string[]>([])
  public onRemoveBookmarks = new BehaviorSubject<string>('');
  // bookmakr
  getBookmark() {
    return this.http.get<Array<{ productId: string; product: Products }>>(
      `${environment.API_URL}buyer/bookmark`
    );
  }
  setBookmark(id: string) {
    this.bookmarkedProducts.next([...this.bookmarkedProducts.getValue(), id])
    let body = new URLSearchParams();
    body.set('productId', id);
    return this.http.post(`${environment.API_URL}buyer/bookmark`, body, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
  }
  public updateLanguage(body: any){
    return this.http.put<User>(`${environment.API_URL}buyer/language`, body);
  }
  removeBookmark(id: string) {
    this.onRemoveBookmarks.next(id);
    let allBookmarks = this.bookmarkedProducts.getValue()
    allBookmarks = allBookmarks.filter(bookmark=> bookmark !== id)
    this.bookmarkedProducts.next(allBookmarks)
    return this.http.delete(
      `${environment.API_URL}buyer/bookmark?bookmarkId=${id}`
    );
  }
  emptyBookmakr(){
    this.bookmarkedProducts.next([])
  }

  // user
  public userInfo() {
    return this.http.get<User>(`${environment.API_URL}buyer/user`);
  }
  public editCompanyInfo(data: Partial<User>) {
    return this.http.put<User>(
      `${environment.API_URL}buyer/company-info`,
      data
    );
  }
  public updateSecEmail(body: Partial<User>){
    return this.http.put(`${environment.API_URL}buyer/secondary-email`, body);
  }
  public updateUserInfo(data: Partial<User>) {
    return this.http.put<User>(`${environment.API_URL}buyer/info`, data);
  }
  public updateUserTimezone(data: any) {
    return this.http.put<User>(`${environment.API_URL}buyer/timezone`, data);
  }
  public changePassword(data: any) {
    let body = new URLSearchParams();
    for (const [key, value] of Object.entries(data)) {
      body.set(key, value as any);
    }
    return this.http.post<User>(
      `${environment.API_URL}buyer/change/password`,
      body,
      { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
    );
  }

  // products
  public productOffers(id: string, startDate?:number, cheapest?:boolean) {
    if(startDate){
      return this.http.get<Products>(
        `${environment.API_URL}buyer/offers/product/${id}?startDate=${startDate}`
      );
    }
    if(cheapest){
      return this.http.get<Products>(
        `${environment.API_URL}buyer/offers/product/${id}/cheapest`
      );
    }
    return this.http.get<Products>(
      `${environment.API_URL}buyer/offers/product/${id}/fastest`
    );
  }
  public productSelected(id: string, startDate:number) {
    if(startDate){
      let body = new URLSearchParams();
      body.set('id', id);
      return this.http.post<Products>(
        `${environment.API_URL}buyer/offers/select?startDate=${startDate}`,
        body,
        { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
      );
    }
    
    let body = new URLSearchParams();
    body.set('id', id);
    return this.http.post<Products>(
      `${environment.API_URL}buyer/offers/select`,
      body,
      { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
    );
   
  }
  public productPaymentLink(id: string) {
    return this.http.get<{ url: string }>(
      `${environment.API_URL}buyer/offers/payment-link/order/${id}`
    );
  }

  public paymentLinkSteps(orderId:string, stepId:string){
    return this.http.get<{ url: string }>(
      `${environment.API_URL}buyer/offers/payment-link/order/${orderId}/step/${stepId}`
    );
  }

  public getMinMax(id: string) {
    return this.http.get(
      `${environment.API_URL}buyer/products/${id}/offer`
    );
  }


  // order
  public getOrder(id: string) {
    return this.http.get<{ url: string }>(
      `${environment.API_URL}buyer/orders/${id}`
    );
  }

  public cancelOrder(id:string){
    return this.http.put<{ url: string }>(
      `${environment.API_URL}buyer/orders/${id}/cancel-order`,{}
    );
  }

  public orders(status: 'active' | 'future' |'ready-for-payment' | 'completed'| 'canceled') {
    return this.http.get<Array<Offer>>(
      `${environment.API_URL}buyer/orders/status/${status}`
    );
  }

  public completeStep(orderId:string, stepId:string) {
    return this.http.put<Order>(
      `${environment.API_URL}buyer/orders/${orderId}/step/${stepId}/complete`,
      {}
    
    );
  }
  public updateCurrencyCode(currencyCode: string){
    return this.http.put<User>(`${environment.API_URL}buyer/currency`, {currencyCode});
  }
}
