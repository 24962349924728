import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtmlTag',
  standalone: true
})
export class RemoveHtmlTagPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if(!value){
      return value
    }

    return value.replace(/<[^>]*>/g, '');
  }

}
