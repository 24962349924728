import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'space',
  standalone: true,
})
export class SpacePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(!value){
      return
    }
    if(value.includes("NZ$")){
      return value.replace(/NZ\$/, "NZ$ ");
    }
    else{
      return value.substring(0, 1) + ' ' + value.substring(1);
    }
  }
}
