import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Products } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FeaturedDataServiceService {

  private featuredProducts = new BehaviorSubject<Array<Products>>([])
  constructor() { }
  // TODO
  // show error when 3 products are added
  setFeaturedProducts(product: Products){
    const currentData = this.featuredProducts.getValue()
    const found = currentData.find(prod => prod.id == product.id);
    if(found){
      const updatedData = currentData.filter(prod=> prod.id !== product.id)
      this.featuredProducts.next(updatedData) 
    }else{
      this.featuredProducts.next([...currentData, product]) 
    }
    console.log('currentData', this.featuredProducts.getValue());
  }

  setNewFeaturedProd(products: Array<Products>){
    this.featuredProducts.next(products) 
  }

  getFeaturedProducts(){
    return this.featuredProducts.asObservable()
  }
  checkIfFeatured(productId: String){
    const currentData = this.featuredProducts.getValue()
    return currentData.find(prod => prod.id == productId);
  }
  getLength() {
    return this.featuredProducts.getValue().length
  }
}
