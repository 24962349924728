import { DEFAULT_CURRENCY_CODE, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserApiService } from '../../user/services/user-api.service';
import { ExternalService } from '../api/external.service';
import { AuthService } from './auth.service';

export interface BuyerCurrencyI {
  code: string,
  exchangeRate: number
}

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private defaultCurrency = {exchangeRate: 1, code: 'USD'}
  private _currency = new BehaviorSubject<BuyerCurrencyI>(this.defaultCurrency)
  
  constructor(private buyerService: UserApiService, private external: ExternalService, private authService: AuthService) { }
  public currency(){
    return this._currency.asObservable()
  }
  public setBuyerCurrency(code: string){
    console.log('setBuyerCurrency', code);
    if(this.authService.isBuyer()){
      this.buyerService.updateCurrencyCode(code).subscribe(res=>{});
    }
    localStorage.setItem('CURRENCY', code)
    this.external.exchangeRate(code).subscribe(res=>{
      console.log('buyerCurrencyCode', code);
      this._currency.next({code:code, exchangeRate: res})
    })
  }
  public setCurrency(code: string){
    console.log('setCurrency', code);
    
    this._currency.next({code:code, exchangeRate: 1})
  }

  public getCurrency(){
    const tempData = {...this._currency.getValue()}
    return tempData;
  }

  public setMarketplaceCurrency(){
    this.external.getMarketplace().subscribe(res=>{
      this.setCurrency(res.currencyCode)
    })
  }
}
