import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BuyerCurrencyI } from '../services/currency.service';

@Pipe({
  name: 'customCurrency',
  standalone: true,
  
})
export class CustomCurrencyPipe implements PipeTransform {
  // injec
  constructor(private currencyPipe: CurrencyPipe) { }
  transform(value: any, currency: BuyerCurrencyI, display?: string | boolean, digitsInfo?: string, locale?: string): string | null {
    return this.currencyPipe.transform((value/100) * currency.exchangeRate, currency.code, 'symbol', '1.0-0');
  }

}
